import * as React from "react"
import Layout from "../components/Layouts/Layout/Layout"
import Seo from "../components/seo"

const ServerErrorPage = () => (
  <div>
    <Layout>
      <section>
        <div 
          className="container flex justify-content-center align-items-center flex-direction-column"
          style={{
            paddingBlock: "120px"
          }}>
          <h1>500</h1>
          <p>Le serveur a rencontré un problème inattendu qui l'empêche de répondre à la requête.</p>
        </div>
      </section>
    </Layout>
  </div>
)

export const Head = () => <Seo title="500" />

export default ServerErrorPage
